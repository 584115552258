import Vue from 'vue'
import Buefy from 'buefy'

Vue.use(Buefy, {
  "css": false,
  "materialDesignIcons": false,
  "materialDesignIconsHRef": "https://cdn.jsdelivr.net/npm/@mdi/font@5.8.55/css/materialdesignicons.min.css",
  "async": true,
  "defaultIconComponent": "font-awesome-icon",
  "defaultIconPack": "fas"
})
import moment from 'moment'

import 'moment/locale/ru'
import 'moment/locale/uk'

moment.locale('uk')

export default (ctx, inject) => {
  ctx.$moment = moment
  inject('moment', moment)
}
